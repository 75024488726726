import axios from 'axios';

const message = {
  error: 'Error...',
  loading: 'Loading info...',
  changes: 'Recent changes not saved.',
  saving: 'Saving changes...',
  saved: 'Changes Saved',
  check: 'Checking for other changes...',
  downloading: 'Downloading Excel file...',
};

function displayStatus(text) {
  if (document.getElementById('status')) {
    document.getElementById('status').innerHTML = text || '';
  }
  if (text) console.log(text);
}

/** ============================================= API FOR DEV ============================================= **/




/**
 * @returns {Object[]} Retrieve all videos in the form of an array
 */
async function getVideos() {
  console.log("Retriving all videos...");

  try {
    displayStatus(message.loading);
    
    const videos = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/db/videos/all`);
    
    displayStatus();
    return videos.data;
  } catch (err) {
    displayStatus(message.error);
    console.log('error: ', err);
    return [];
  }
}


/**
 * 
 * @param {string} vid_id 
 * @returns {Object} Retrives all video information for a given video id
 */
async function getVideo(vid_id) {
  console.log(`Retrieving video id: ${vid_id}`);

  try {
    displayStatus(message.loading);

    const video = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/db/videos/${vid_id}`);
    
    displayStatus();
    return video.data;
  } catch (err) {
    displayStatus(message.error);
    console.log('error: ', err);
    return {};
  }
}


/**
 * 
 * @param {string} vid_id 
 * @returns {Object} Retrieves basic video information given a video id
 */
async function getVideoDetails(vid_id) {
  console.log(`Retrieving details for video id: ${vid_id}`);

  try {
    displayStatus(message.loading);
    
    const video = await getVideo(vid_id);
    console.log(`Video info loaded for video id: ${vid_id}`);

    displayStatus();

    return {
      video_id: video.uvid,
      name: `${video.video_name}.${video.video_extension}`,
      url: video.url,
      duration: video.duration,
      dv_cycle_status: video.dv_cycle_status,
    };
  } catch (err) {
    displayStatus(message.error);
    console.log('error: ', err);
    return {};
  }
}


/**
 * 
 * @param {string} vid_id 
 * @returns {boolean} Flag whether video was successfully updated
 */
async function updateVideoDetails(vid_id) {
  try {
    displayStatus(message.loading);

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/db/videos/mark_complete/`,
      { uvid: vid_id }
    );

    displayStatus();
    return res.data;
  } catch (err) {
    displayStatus(message.error);
    console.log('error: ', err);
    return false;
  }
}


/**
 * 
 * @param {string} vid_id 
 * @returns {Object} Array of frames labelled for the given video id
 */
async function getFrames(vid_id) {
  console.log(`Retrieving frames for video id: ${vid_id}`);

  try {
    displayStatus(message.loading);

    const frames = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/db/frames/${vid_id}`);
    console.log(`Frame info loaded for video id: ${vid_id}`);

    displayStatus();
    return frames.data;
  } catch (err) {
    displayStatus(message.error);
    console.log('error: ', err);
    return [];
  }
}


/**
 * 
 * @param {Object} frame 
 * @returns {boolean} Flag whether frame was successfully posted
 */
async function postFrame(frame) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/db/frames/post/`,
      { frame }
    );

    if (res.data)
      console.log(`Frame ${frame.ufid} successfully posted!`);
    else
      console.error(`Frame ${frame.ufid} failed to post!`);

    return res.data;
  } catch (err) {
    return err;
  }
}

/**
 * 
 * @param {Object} frame 
 * @returns {boolean} Flag whether frame was successfully updated
 */
async function updateFrame(frame) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/db/frames/update/`,
      { frame }
    );

    if (res.data)
      console.log(`Frame ${frame.ufid} successfully updated!`);
    else
      console.error(`Frame ${frame.ufid} failed to update!`);

    return res.data;
  } catch (err) {
    return err;
  }
}

export {
  message,
  displayStatus,
  getVideos,
  getVideo,
  getVideoDetails,
  updateVideoDetails,
  getFrames,
  postFrame,
  updateFrame,
};
