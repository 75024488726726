// Core
import React from 'react';
import './DataLabelling.css';

// UI
import Select from 'react-select';

// Others
import {
  numberStates,
  pressStates,
  deceptionStates,
  pressStateColors,
  specialCases,
  deceptionCases,
  fps,
} from '../../config';

class DataLabelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      label: 'None',
    };
  }

  displayAnnotations = (frameNumber) => {
    if (!this.props.player.getInternalPlayer()) return;

    const { total_frames, property } = this.props;

    // get label for current frame and property
    let label = total_frames[frameNumber]
      ? total_frames[frameNumber][property] || 'None'
      : 'None';
    const changed =
      total_frames[frameNumber] &&
      total_frames[frameNumber][`${property}_changed`];

    if (specialCases.includes(property)) {
      label = label === 'None' ? 0 : label;

      // find last changed respiration/heartrate value
      if (changed !== true) {
        const filtered = Object.keys(total_frames).filter(
          (fr) => fr < frameNumber && total_frames[fr][`${property}_changed`]
        );
        if (filtered.length > 0) {
          label = total_frames[filtered[filtered.length - 1]][property];
        }
      }
    }

    this.setState({
      label: label,
      changed: changed ? true : false,
    });
  };

  selectLabels = (event) => {
    if (this.props.player) {
      let video = this.props.player.getInternalPlayer();
      if (video) {
        const currentFrame = Math.round(
          this.props.player.getCurrentTime() * fps
        );
        if (
          currentFrame === 0 ||
          currentFrame === Math.round(this.props.player.getDuration() * fps)
        )
          return;

        // toggle between actions
        if (
          video.paused &&
          (event.key === 'ArrowUp' || event.key === 'ArrowDown')
        ) {
          let indexToSet = pressStates.indexOf(this.state.label);
          indexToSet =
            event.key === 'ArrowDown' ? indexToSet + 1 : indexToSet - 1;
          // ensure that key actions allow user to loop through buttons
          // (e.g. none -> onset -> apex-A ... apex-E -> offset -> none -> onset ...)
          indexToSet = (pressStates.length + indexToSet) % pressStates.length;
          const valueToSet = pressStates[indexToSet];

          this.setState({ label: valueToSet });
          this.props.updateFrameLabels(this.props.property, valueToSet, false);
        } else {
          this.props.videoKeyControls(event);
        }
      }
    }
  };

  selectDeceptionLabels = (event) => {
    if (this.props.player) {
      let video = this.props.player.getInternalPlayer();
      if (video) {
        const currentFrame = Math.round(
          this.props.player.getCurrentTime() * fps
        );
        if (
          currentFrame === 0 ||
          currentFrame === Math.round(this.props.player.getDuration() * fps)
        )
          return;

        // toggle between actions
        if (
          video.paused &&
          (event.key === 'ArrowUp' || event.key === 'ArrowDown')
        ) {
          let indexToSet = deceptionStates.indexOf(this.state.label);
          indexToSet =
            event.key === 'ArrowDown' ? indexToSet + 1 : indexToSet - 1;
          // ensure that key actions allow user to loop through buttons
          // (e.g. none -> onset -> apex-A ... apex-E -> offset -> none -> onset ...)
          indexToSet =
            (deceptionStates.length + indexToSet) % deceptionStates.length;
          const valueToSet = deceptionStates[indexToSet];

          this.setState({ label: valueToSet });
          this.props.updateFrameLabels(this.props.property, valueToSet, false);
        } else {
          this.props.videoKeyControls(event);
        }
      }
    }
  };

  selectNumber = (event) => {
    if (this.props.player) {
      let video = this.props.player.getInternalPlayer();
      if (video) {
        const currentFrame = Math.round(
          this.props.player.getCurrentTime() * fps
        );
        if (
          currentFrame === 0 ||
          currentFrame === Math.round(this.props.player.getDuration() * fps)
        )
          return;

        // toggle between actions
        if (
          video.paused &&
          (event.key === 'ArrowUp' || event.key === 'ArrowDown')
        ) {
          let indexToSet = numberStates.indexOf(
            this.state.label === 'None' ? 0 : this.state.label
          );
          indexToSet =
            event.key === 'ArrowUp' ? indexToSet + 1 : indexToSet - 1;
          // ensure that key actions allow user to loop through buttons
          indexToSet = (numberStates.length + indexToSet) % numberStates.length;
          const valueToSet = numberStates[indexToSet];

          this.setState({ label: valueToSet });
        } else {
          this.props.videoKeyControls(event);
        }
      }
    }
  };

  setNumber = (event) => {
    const currentFrame = Math.round(this.props.player.getCurrentTime() * fps);
    if (
      currentFrame === 0 ||
      currentFrame === Math.round(this.props.player.getDuration() * fps)
    )
      return;

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.setState({ changed: this.state.label > 0 }, () => {
        this.props.updateFrameLabels(
          this.props.property,
          this.state.label,
          this.state.changed
        );
      });
    }
  };

  render() {
    const inputType = specialCases.includes(this.props.property) ? (
      <div onKeyUp={this.setNumber.bind(this)}>
        <Select
          tabIndex="0"
          styles={{
            placeholder: (defaultStyles) => {
              return {
                ...defaultStyles,
                color:
                  this.state.label > 0 && this.state.changed
                    ? 'white'
                    : 'black',
              };
            },
            control: (defaultStyles) => {
              return {
                ...defaultStyles,
                background:
                  this.state.label > 0 && this.state.changed
                    ? '#fc565e'
                    : 'white',
              };
            },
          }}
          placeholder={this.state.label}
          menuIsOpen={false}
          isSearchable={false}
          onKeyDown={this.selectNumber.bind(this)}
        />
      </div>
    ) : deceptionCases.includes(this.props.property) ? (
      <Select
        tabIndex="0"
        styles={{
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: this.state.label === 'None' ? 'black' : 'white',
            };
          },
          control: (defaultStyles) => {
            return {
              ...defaultStyles,
              background: pressStateColors[this.state.label],
            };
          },
        }}
        placeholder={this.state.label}
        menuIsOpen={false}
        isSearchable={false}
        onKeyDown={this.selectDeceptionLabels.bind(this)}
      />
    ) : (
      <Select
        tabIndex="0"
        styles={{
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: this.state.label === 'None' ? 'black' : 'white',
            };
          },
          control: (defaultStyles) => {
            return {
              ...defaultStyles,
              background: pressStateColors[this.state.label],
            };
          },
        }}
        placeholder={this.state.label}
        menuIsOpen={false}
        isSearchable={false}
        onKeyDown={this.selectLabels.bind(this)}
      />
    );

    const containerWidth = 100 / this.props.elements.length - 1;
    return (
      <div
        className="action-container"
        style={{
          width: `${containerWidth}%`,
          backgroundColor: this.props.searched ? '#3F51B5' : 'transparent',
        }}
      >
        <div className="action-label">
          {this.props.property.split('_').join(' ').replace('and', '&')}
        </div>
        {inputType}
      </div>
    );
  }
}

export default DataLabelling;
