import { Button, Checkbox, Container, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect } from 'react';
import {
  useNavigate,
  useSearchParams
} from "react-router-dom";
import * as backend from './AppBackend';
import VideoAccess from './components/Admin/VideoAccess';
// Others
import ReactLoading from 'react-loading';
import {
  loadingSpinnerStyle
} from './config';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function VideoAccessPage(){
  const [searchParams, setSearchParams] = useSearchParams();
  const [videoList, setVideoList] = React.useState([]);
  const [userVideoList, setUserVideoList] = React.useState([]);
  const [userEmail, setUserEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();
  const userId = searchParams.get('id');

  useEffect(() => {
    const fetchVideos = async() => {
      setLoading(true);
      var user = await axios
        .get(process.env.REACT_APP_BACKEND_URL + '/users/get/' + userId)
        .then((response) => response.data.user)
        .catch((error) => {
          alert(error.response.data.message);
        });
      var access_list = user.videoAccessId;
      setUserEmail(user.email);
  
      let videos = await backend.getVideos(process.env.REACT_APP_ENV);
      videos = videos.map((video) => {
          return {
            video_id: video.uvid,
            video_name: video.video_name,
            duration: video.duration,
            createdAt: video.createdAt,
            dv_cycle_status: video.dv_cycle_status,
            check: access_list.includes(video.uvid)
          };
      });
      videos.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setVideoList(videos);
        setLoading(false);
    }
  
    const fetchUser = async() => {
      setLoading(true);
      var user = await axios
      .get(process.env.REACT_APP_BACKEND_URL + '/users/get/' + userId)
      .then((response) => response.data.user
      )
      .catch((error) => {
        alert(error.response.data.message);
      });
      setUserEmail(user.email);
      setUserVideoList(user.video_access);
      setLoading(false);
    }
    
    fetchVideos();
    fetchUser();
  }, []);

  const fetchVideos = async() => {
    setLoading(true);
    var user = await axios
    .get(process.env.REACT_APP_BACKEND_URL + '/users/get/' + userId)
    .then((response) => response.data.user
    )
    .catch((error) => {
      alert(error.response.data.message);
    });
    var access_list = user.videoAccessId;
    setUserEmail(user.email);

    let videos = await backend.getVideos();
    videos = videos.map((video) => {
        return {
          video_id: video.uvid,
          video_name: video.video_name,
          duration: video.duration,
          createdAt: video.createdAt,
          dv_cycle_status: video.dv_cycle_status,
          check: access_list.includes(video.uvid)
        };
    });
    videos.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setVideoList(videos);
      setLoading(false);
  }

  const fetchUser = async() => {
    setLoading(true);
    var user = await axios
    .get(process.env.REACT_APP_BACKEND_URL + '/users/get/' + userId)
    .then((response) => response.data.user
    )
    .catch((error) => {
      alert(error.response.data.message);
    });
    setUserEmail(user.email);
    setUserVideoList(user.video_access);
    setLoading(false);
  }

  const handleChange = (videoId) =>{
    const index = videoList.findIndex(video => video.video_id === videoId);
    const videos = [...videoList] // important to create a copy, otherwise you'll modify state outside of setState call
    videos[index].check = !videos[index].check;
    setVideoList(videos);
  }

  const createVideo = (video) => {
    var video_name = `${video.video_name}${
      video.dv_cycle_status === 'labelled' ? ' ✅' : ''
    }`;
    var duration = video.duration;
    var createdAt = video.createdAt;
    var checkBox = <Checkbox checked={video.check} onChange={() => handleChange(video.video_id)}/>;
    var actions = [checkBox];

    return { video_name, duration, createdAt, actions };
  }

  const navigateBack = () => {
    return navigate("/admin");
  };

  const updateVideoAccess = async() => {
    var access_list = videoList.filter((video) => {
        if(video.check) return video;
    });
    axios
    .post(process.env.REACT_APP_BACKEND_URL + '/users/update/', {id: userId, video_access: access_list})
    .then((response) => { alert("acccess updated")}
    )
    .catch((error) => {
      alert(error.response.data.message);
    });
  }

    return (
      <Container>
        <div
          id="loading"
          style={{ display: loading ? 'block' : 'none' }}
        >
          <ReactLoading
            type="spin"
            height={'20%'}
            width={'20%'}
            style={loadingSpinnerStyle}
          />
        </div>
        <Button variant="outlined" onClick={navigateBack}>Back</Button>
        <Grid container spacing={2} style={{margin: "auto auto"}}>
        <Grid item xs={12}>
            <Item><h1>Video access list for {userEmail}</h1></Item>
          </Grid>
          <Grid item xs={10}>
            <Item>
              <h2>List of videos</h2>
              <VideoAccess videos={videoList} createVideo={createVideo}/>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
                <Button variant="contained" onClick={updateVideoAccess}>Update Access</Button>
            </Item>
          </Grid>
        </Grid>
      </Container >
      );
}


export default VideoAccessPage;
