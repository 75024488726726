import React from 'react';
import './CollapsibleTable.css';

import { makeStyles } from '@material-ui/core/styles';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';

import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

function Row(props) {
  const { row, video } = props;

  function select() {
    // highlight selected row in grey
    document.getElementById('table-body').childNodes.forEach((r) => {
      if (r.id)
        document.getElementById(r.id).style.backgroundColor =
          r.id === 'row' + row.frame ? '#d1cfcf' : 'transparent';
    });
    video.seekTo(row.frame / 30, 'seconds');
  }

  return (
    <TableRow id={`row` + row.frame} onClick={select}>
      <TableCell>{row.frame}</TableCell>
      {props.rowTitles.map((title) => {
        const condition =
          title === 'heart_rate' || title === 'respiration' ? '0' : 'None';
        return (
          <TableCell key={title} align="right">
            {row[title] !== condition
              ? row[title] !== undefined
                ? row[title]
                : '-'
              : '-'}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const useStyles = makeStyles({
  root: { width: '100%' },
  container: { maxHeight: '900px' },
});

function CollapsibleTable(props) {
  let classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  const [checked, setChecked] = React.useState(false);
  const inputFile = React.useRef(null);

  const uvid = ''; //TODO: Pass uvid to this function's props + set the toggleLabelling button to true/false depending on the current dv_cycle_status

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openUploadInput = (event) => {
    inputFile.current.click();
  };

  const toggleLabelling = () => {
    //TODO: Toggle correctly...
    setChecked((prev) => !prev);
    fetch(
      'https://lh3rrwrw4h.execute-api.ap-southeast-1.amazonaws.com/default/dvTracker/',
      {
        method: 'PUT',
        headers: new Headers({
          'x-api-key': '5Vjd6Yzl2hsFe1g9RTgUNsIwoOl21sEP4ZZOD200',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          uvid: uvid,
          dv_cycle_status: 'pending_engine_processing',
        }),
      }
    ).catch(console.log);
  };

  const incomplete =
    Object.keys(props.video_source).length > 0 &&
    props.video_source.dv_cycle_status !== 'labelled';

  return (
    <div>
      <Paper>
        <TableContainer className={classes.container}>
          <Table aria-label="collapsible table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Frame</TableCell>
                {props.rowTitles.map((title) => {
                  return (
                    <TableCell key={title} align="right">
                      {title.split('_').join(' ').replace('and', '&')}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody id="table-body">
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.frame}
                    video={props.video}
                    rowTitles={props.rowTitles}
                    row={row}
                  />
                ))}
              {emptyRows > 0 && <TableRow style={{ height: 33 * emptyRows }} />}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {props.rows.length > rowsPerPage ? (
            <Button onClick={() => setPage(0)}>first</Button>
          ) : (
            ''
          )}
          <TablePagination
            rowsPerPageOptions={[15, 20, 100]}
            component="div"
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {props.rows.length > rowsPerPage ? (
            <Button
              onClick={() =>
                setPage(Math.ceil(props.rows.length / rowsPerPage) - 1)
              }
            >
              last
            </Button>
          ) : (
            ''
          )}
        </div>
      </Paper>

      <div className="action-buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.downloadCSV()}
        >
          Download CSV
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.downloadCSV(true)}
        >
          Download CSV (with Image)
        </Button>
        &nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.refresh()}
        >
          Refresh
        </Button>
        {/* &nbsp; */}
        {incomplete ? (
          <>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.updateVideoStatus()}
            >
              Mark As Complete
            </Button>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="action-buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.downloadAllCsv()}
        >
          Download All CSV (No Images)
        </Button>
        <Button variant="contained" color="primary" onClick={openUploadInput}>
          Upload Processing Data
        </Button>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          accept=".csv"
          onChange={(event) => props.uploadProcessingCsv(event)}
        />
      </div>
    </div>
  );
}

export { CollapsibleTable };
