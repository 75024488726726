import React, { useState, useEffect, useContext, createContext } from "react";

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  // Wrap any methods we want to use making sure ...
  // ... to save the user to state.
  const handleTokenCallback = (token) => {
      setUser(token)
      return token;
  }

  const signout = () => {
    setUser(false);
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    //check token expiry
    // Cleanup subscription on unmount
    //return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    handleTokenCallback,
    signout,
  };
}