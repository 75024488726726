// Core
import { Button } from '@mui/material';
import React from 'react';
import { LoginStore } from "../../LoginStore";

class Logout extends React.Component {
  render() {
    return (
        <div>
        <Button size="small" variant="contained" onClick={() =>
          LoginStore.update(s => {
            s.token = "";
            s.email = "";
          })
        }>Logout</Button>
      </div>
    );
  }
}

export default Logout;
