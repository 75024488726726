// Core
import React from 'react';
import { TextField, Button } from '@mui/material';

class LoginField extends React.Component {
  render() {
    return (
        <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h3>Login</h3>
        <TextField type="email" id="outlined-basic" label="Email" variant="outlined" value={this.props.email} onChange={this.props.emailTextChange}/>
        <br/>
        <Button variant="contained" onClick={this.props.sendEmail}>Login with Email</Button>
      </div>
    );
  }
}

export default LoginField;
