// Core
import React from 'react';
import { Button } from '@mui/material';
import {
    useNavigate
  } from "react-router-dom";

function Admin(){
    let navigate = useNavigate();

    return (
        <div
        style={{
          marginTop:"5px"
        }}
      >
        <Button size="small" variant="contained" onClick={() =>
         navigate("/admin")
        }>Admin Page</Button>
      </div>
    );
  }

export default Admin;
