// Core
import axios from 'axios';
import React from 'react';
import {
  BrowserRouter as Router, Navigate, Route, Routes, useLocation
} from "react-router-dom";
import AdminPage from './AdminPage.js';
import HandleTokenCallBackPage from './components/Login/HandleTokenCallBackPage.js';
import LabellingTool from './LabellingTool.js';
import Login from './Login.js';
import { LoginStore, rehydrateStore } from "./LoginStore";
import { ProvideAuth } from "./UseAuth.js";
import VideoAccessPage from './VideoAccessPage.js';



/** ============================================= APP CLASS ============================================= **/

function App(props) {
  rehydrateStore();
  const token = LoginStore.useState(s => s.token)

  axios.interceptors.request.use(request => {
    const authEndpoints = [
      "users/.*",
      "auth/check-admin",
      "videos/extract_video_labelling_frame/.*",
      "db/.*",
    ]

    const re = new RegExp(`^${process.env.REACT_APP_BACKEND_URL}/(${authEndpoints.join("|")})$`, "g");
    const match = request.url.match(re);

    // append token if the endpoints require them, those without Public() decorator in backend
    if (match !== null && match[0] === request.url) {
      if (!request.url.includes("?token=")) request.url += `?token=${token}`;
    }

    return request;
  }, error => {
    // Do something with request error
    return Promise.reject(error);
  });

  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if(err.response.status === 401)
      LoginStore.update(s => {
        s.token = "";
      });
      return Promise.reject(err);
    } 
  );
  
    return (
      <ProvideAuth>
        <Router>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path="/auth/magiclogin/callback" element={<HandleTokenCallBackPage/>}/>
            <Route path="/" element={
              <PrivateRoute>
                <LabellingTool/>
              </PrivateRoute>
            }/>
            <Route path="/admin" element={
              <PrivateRoute>
              <AdminPage/>
            </PrivateRoute>
            }/>
            <Route path="/video_access" element={
              <PrivateRoute>
              <VideoAccessPage/>
            </PrivateRoute>
            }/>
          </Routes>
      </Router>
    </ProvideAuth>
    );
}
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children }) {
  let token = LoginStore.useState(s => s.token);
  let location = useLocation();
  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
