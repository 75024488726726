// Core
import axios from 'axios';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ReactLoading from 'react-loading';
import ReactPlayer from 'react-player';
import * as backend from './AppBackend';

// UI
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';

// In-house
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import './App.css';

// Components
import DataRetrospective from './components/DataRetrospective/DataRetrospective';
import LabellingDashboard from './components/LabellingDashboard/LabellingDashboard';
import Admin from './components/Login/Admin';
import Logout from './components/Login/Logout';
import Scrubber from './components/Scrubber/Scrubber';
import DataOverlay from './components/VideoOverlay/DataOverlay';
//other modules
import AudioSpectrum from 'react-audio-spectrum';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import Papa from 'papaparse';

// Others
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {
  categories, deceptionStates, elements, gridResolution, loadingSpinnerStyle, minGridLeft,
  minGridRight, pressStates, save_interval_ms, specialCases, zoomStates
} from './config';

// import { useState } from 'react';
// import { DropzoneDialog } from 'material-ui-dropzone';
// import Papa from 'papaparse';

const ResponsiveGridLayout = WidthProvider(Responsive);

var total_frames = {}; // temporarily store frames for current video
var unsaved_frames = {}; // temporarily store unsaved frames for current video
var canvas_interval;
var save_interval;
var mediaTime;
var frameOffset = 0;
var timeOffset = 0;
var startTime = 0;

//Accredited to capture-video-frame.js with RD-modifications to run on later versions of React
function captureVideoFrame(video, format, quality) {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }

  format = format || 'jpeg';
  quality = quality || 1;

  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false;
  }

  var canvas = document.createElement('CANVAS');

  canvas.width = 1280;
  canvas.height = 720;
  // fill vertically
  var vRatio = (canvas.height / video.videoHeight) * video.videoWidth;
  canvas.getContext('2d').drawImage(video, 0, 0, vRatio, canvas.height);

  var dataUri = canvas.toDataURL('image/' + format, quality);
  var data = dataUri.split(',')[1];
  var mimeType = dataUri.split(';')[0].slice(5);

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
}

function setDelay(i) {
  setTimeout(function () {
    console.log(i);
  }, 1000);
}

/** ============================================= APP CLASS ============================================= **/

class LabellingTool extends React.Component {
  constructor(props) {
    super(props);

    this.responsiveHeight = 4;
    this.state = {
      admin: false,
      open: false,
      loading: false,
      logo_dropdown: false,
      current_env: process.env.REACT_APP_ENV,
      current_page: 'dataLabelling', // 'dataLabelling' or 'settings'
      current_category: Object.values(categories)[0],
      video_sources: [], // list of videos at user's disposal to label
      video_source: {}, // current video source
      video_zoom: false, // if true (mouse hovers over video), show zoom canvas
      video_zoom_index: 0, // keep track of current index in variable "zoomStates"
      video_loading: false,
      video_seeking: false,
      video_ready: false,
      frame_processing: false,
      save_inprogress: false,
      volume: 0,
      playback_rate: 1,
      paused: true,
      current_frame: 0,
      current_mediatime: 0,
      fps: 0,
      rows: [], // customized data format used to display in data table,
      processing_data: {}, //data to use in processing overlay
      current_displayed_processing_data: {},
      layout: [
        // used to update responsive grid width
        {
          i: 'left-container',
          x: 0,
          y: 0,
          w: gridResolution - minGridRight,
          h: this.responsiveHeight,
          minW: minGridLeft,
          maxW: gridResolution - minGridRight,
          minH: this.responsiveHeight,
          maxH: this.responsiveHeight,
          isDraggable: false,
        },
        {
          i: 'right-container',
          x: gridResolution - minGridRight,
          y: 0,
          w: minGridRight,
          h: this.responsiveHeight,
          minW: minGridRight,
          maxW: gridResolution - minGridLeft,
          minH: this.responsiveHeight,
          maxH: this.responsiveHeight,
          isDraggable: false,
          isResizable: false,
          isBounded: true,
        },
      ],
    };

    this.datalabellingElement = [];
    for (let i = 0; i < elements.length; i++) {
      this.datalabellingElement[i] = [elements[i][0], React.createRef()];
    }

    this.updating_layout = false;
  }

  async componentDidMount() {
    this.setState({ loading: true });

    let videos = []
    // get ids and names of all videos for labeller to choose from
    var admin = await axios.get(process.env.REACT_APP_BACKEND_URL + "/auth/check-admin");
    admin = admin.data[0];
    this.setState({admin: admin});

    if(this.state.admin){
      videos = await backend.getVideos();
    }else{
      var accessList = await axios.get(process.env.REACT_APP_BACKEND_URL + "/users/current-video-access");
      accessList = accessList.data[0];
      for(var i = 0; i < accessList.length; i++){
        var video =await backend.getVideo(accessList[i]);
        videos.push(video);
      }
    }
    
    if (videos.length === 0) {
      this.setState({
        loading: false,
        video_sources: [],
        video_source: {},
      });
      total_frames = [];
      return;
    }
    videos = videos.map((video) => {

      return {
        video_id: video.uvid,
        label: `${video.video_name}.${video.video_extension}${
          video.dv_cycle_status === 'labelled' ? ' ✅' : ''
        }`,
        duration: video.duration,
        lastFrameNumber: 0,
        createdAt: video.createdAt,
      };
    });
    this.setState({ video_sources: videos });

    // // get video data
    // await this.getVideoDetails(videos[0].video_id);
    // await this.getVideoFrames(videos[0].video_id);

    // reset interval
    if (save_interval) clearInterval(save_interval);
    save_interval = setInterval(this.saveData, save_interval_ms);

    this.renderDataPanel();
    this.setState({ loading: false });
  }

  componentDidUpdate() {
    if (this.updating_layout) return;

    let sumHeight = 0;
    for (let item of document.getElementById('left-container').children) {
      sumHeight += item.offsetHeight;
    }

    // manual way to fit items into responsive grid
    this.setState(
      {
        layout: this.state.layout.map((l) => {
          let newLayout = { ...l };
          newLayout.h = sumHeight / 158;
          newLayout.minH = sumHeight / 158;
          newLayout.maxH = sumHeight / 158;
          return newLayout;
        }),
      },
      () => {
        // prevent componentDidUpdate from being triggered again immediately (will go into infinite loop)
        this.updating_layout = true;
        setTimeout(() => (this.updating_layout = false), 5);
      }
    );
  }

  // onSave = async (filesToUpload) => {
  //   const file = filesToUpload[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     Papa.parse(reader.result, {
  //       complete: async (e) => {
  //         const cols = e.data[0];
  //         const data = e.data.slice(1, e.data.length - 1);

  //         const floatValues = ['time', 'heart_rate', 'respiration_rate'];

  //         for (const f of data) {
  //           let frame = {
  //             uvid: '7f4c6aff-a863-45c6-9257-45296d85e3ce',
  //           };
  //           f.forEach((val, ind) => {
  //             const columnName = cols[ind];
  //             let value = val.replace('-', 'None');
  //             if (columnName === 'frame') {
  //               value = parseInt(value);
  //             } else if (floatValues.includes(columnName)) {
  //               value = parseFloat(val.replace('-', '0'));
  //             } else if (val === '-') {
  //               value = val.replace('-', 'None');
  //             } else {
  //               value = val;
  //             }
  //             frame[columnName] = value;
  //           });
  //           console.log(frame);
  //           const utcTime = new Date().toISOString();
  //           const alpha_version = '1.01';
  //           const ufid = `${frame.uvid}_${frame.frame}_${alpha_version}`;
  //           await backend.postFrame({
  //             ufid: ufid,
  //             createdAt: utcTime,
  //             updatedAt: utcTime,
  //             ...frame,
  //           });
  //         }
  //       },
  //     });
  //   };
  //   reader.readAsBinaryString(file);
  //   this.setState({ open: false });
  // };

  changeCategory = (cat) => {
    this.setState({ current_category: cat }, () => {
      this.renderDataPanel();
      this.renderLabellingPanel(this.getFrameNumber());
    });
  };

  updateVideoFrame = (now, metadata) => {
    if (this.state.video_ready) {
      //set frameOffset with the first frame
      if (startTime === 0) {
        startTime = now;
        frameOffset = Math.round(metadata.mediaTime * this.state.fps);
        timeOffset = metadata.mediaTime;
      }
      let calculated_frame =
        Math.round(metadata.mediaTime * this.state.fps) - frameOffset; // +1 is added if you count frame 0 as frame 1... Semantics
      mediaTime = metadata.mediaTime;
      this.setState({
        current_frame: calculated_frame,
        current_mediatime: mediaTime,
        frame_processing: false,
      });
      this.renderLabellingPanel(this.getFrameNumber());
      this.renderDisplayedProcessingData(this.getFrameNumber());

      this.player
        .getInternalPlayer()
        .requestVideoFrameCallback(this.updateVideoFrame);
    }
  };

  /** ============================================= HELPERS ============================================= **/

  // ensure that users edit on accurate frame intervals
  toNearestFrame = (t) => {
    return Math.round(t * this.state.fps) / this.state.fps;
  };

  // configure data format
  createData = (index) => {
    let obj = {
      frame: index,
      time: this.state.current_mediatime,
    };
    elements.forEach((prop) => (obj[prop[1]] = '-'));
    return obj;
  };

  // ===================================== DATA HANDLING FUNCTIONS ===================================== /

  selectEnvironment = async (env) => {
    if (env.value === this.state.current_env) return;

    // save any unsaved changes before changing env
    await this.saveData();
    // change environment state
    this.setState({ current_env: env.value }, async () => {
      await this.componentDidMount();
    });
  };

  getVideoDetails = async (video_id) => {
    let srcObj = await backend.getVideoDetails(
      video_id
    );
    srcObj.url = encodeURI(srcObj.url);
    this.setState({
      video_source: srcObj,
    });
  };

  getVideoFrames = async (video_id) => {
    // convert array into object with frame number as key
    let frames = await backend.getFrames(video_id);
    total_frames = {};

    frames.forEach((f) => {
      //prevent negative frames from being included
      if (f.frame >= 0) {
        specialCases.forEach((prop) => (f[`${prop}_changed`] = f[prop] > 0));
        total_frames[f.frame] = JSON.parse(JSON.stringify(f));
      }
    });
  };

  updateVideoStatus = async () => {
    if (!this.state.video_source.video_id) return;

    this.setState({ loading: true });
    await this.saveData();
    var onsetCounter = {};
    var offsetCounter = {};
    var errorMessage = [];
    var deceptionCat = elements
      .filter((e) => e[0] === 'deception')
      .map((e) => e[1]);

    deceptionCat.forEach((cat) => {
      onsetCounter[cat] = [];
      offsetCounter[cat] = [];
    });

    for (var frame in total_frames) {
      for (var property in total_frames[frame]) {
        let currentFrame = total_frames[frame];
        if (deceptionCat.includes(property) && currentFrame[property]) {
          if (currentFrame[property].includes('Onset')) {
            onsetCounter[property].push(currentFrame.frame);
            //handle onset -> onset situation
            if (
              onsetCounter[property].length >
              offsetCounter[property].length + 1
            ) {
              offsetCounter[property].push(0);
              errorMessage.push(
                'No offset found for ' +
                  property +
                  ' at frame ' +
                  onsetCounter[property][onsetCounter[property].length - 2]
              );
            }
          }
          if (currentFrame[property] === 'Offset') {
            offsetCounter[property].push(currentFrame.frame);
            //no onset before offset
            if (
              onsetCounter[property].length < offsetCounter[property].length
            ) {
              onsetCounter[property].push(0);
              errorMessage.push(
                'Invalid offset position for ' +
                  property +
                  ' at frame ' +
                  currentFrame.frame
              );
            }
          }
        }
      }
    }

    for (var cat in onsetCounter) {
      //no offset for onset
      if (onsetCounter[cat].length !== offsetCounter[cat].length) {
        errorMessage.push(
          'Missing offset for onset position of ' +
            cat +
            ' at frame ' +
            onsetCounter[cat][onsetCounter[cat].length - 1]
        );
      }
    }
    if (errorMessage.length) {
      alert(errorMessage.join('\n'));
      this.setState({ loading: false });
      return;
    }

    const updated = await backend.updateVideoDetails(
      this.state.video_source.video_id
    );

    // only continue if updating succeeded
    if (!updated)
      return;

    let videos = this.state.video_sources;
    let currentVideo =
      videos[
        videos.findIndex((v) => v.video_id === this.state.video_source.video_id)
      ];

    if (currentVideo) {
      currentVideo.label += ' ✅';
    }

    this.setState({
      loading: false,
      video_source: { ...this.state.video_source, dv_cycle_status: 'labelled' },
      video_sources: videos,
    });
  };

  saveData = async () => {
    if(!Object.keys(unsaved_frames).length){
      backend.displayStatus();
      return;
    }
    this.setState({ save_inprogress: true });
    const unsaved_frames_copy = JSON.parse(JSON.stringify(unsaved_frames));
    unsaved_frames = {};
    const newFrames = Object.values(unsaved_frames_copy);
    backend.displayStatus(backend.message.saving);
    try{
      for (const frame of newFrames) {
        const utcTime = new Date().toISOString();
        // if frame exists in db, update frame, else create new frame
        if (frame.ufid) {
          await backend.updateFrame({
            updatedAt: utcTime,
            ...frame,
          });
        } else {
          const alpha_version = '1.01';
          const ufid = `${frame.uvid}_${frame.frame}_${alpha_version}`;
          await backend.postFrame({
            ufid: ufid,
            createdAt: utcTime,
            updatedAt: utcTime,
            ...frame,
          });
          // append ufid to local frame storage
          // reason: when user updates this frame in the future, it will update db instead of create new row
          total_frames[frame.frame]['ufid'] = ufid;
        }
      }
      backend.displayStatus(backend.message.saved);
    }catch(err){
      backend.displayStatus(backend.message.error);
      console.log('error: ', err);
    }
    this.setState({ save_inprogress: false });
  };

  downloadCSV = async (withImage = false) => {
    if (
      (!this.player.getInternalPlayer() ||
        Object.keys(this.state.video_source).length === 0) &&
      !withImage
    )
      return;

    this.setState({ loading: true });

    // in case there are any unsaved data
    await this.saveData();
    let rows = [];
    let filename = '';
    if (Object.keys(this.state.video_source).length) {
      filename = this.state.video_source.name;
      filename = filename.replace('_IR_audiomp4', '');
      filename = filename.replace('_RGB_audiomp4', '');
      filename = filename.replace('.mov', '');
      filename = filename.replace('.mp4', '');
      filename = filename + '_' + this.state.video_source.duration;
      filename = filename.slice(0, 30);
      await this.getVideoFrames(this.state.video_source.video_id);
      rows = this.parseFrameData(total_frames);
    }
    //if video source exists
    if (withImage && Object.keys(this.state.video_source).length) {
      if (rows.length > 1) {
        //save data to csv
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push(filename);
        wb.Sheets[filename] = XLSX.utils.json_to_sheet(rows);
        if (wb.SheetNames.length > 0) {
          const fileType = 'text/csv';
          const excelBuffer = XLSX.write(wb, {
            bookType: 'csv',
            type: 'array',
          });
          const data = new Blob([excelBuffer], { type: fileType });
          let formData = new FormData();
          formData.append('file', data, filename + '.csv');
          
          //const uri = `https://test.realitydetector.com:81/extract_video_labelling_frame/${this.state.video_source.video_id}`;
          const uri = `${process.env.REACT_APP_ENGINE_URL}/videos/extract_video_labelling_frame/${this.state.video_source.video_id}`;

          axios
            .post(
              uri,
              formData,
              { responseType: 'blob' }
            )
            .then((response) => {              
              if (response.data) {
                FileSaver.saveAs(response.data, filename + '.zip');
                this.setState({ loading: false });
              } else {
                throw Error(
                  `Server returned ${response.status}: ${response.statusText}`
                );
              }
            })
            .catch((error) => {
              alert(error);
              this.setState({ loading: false });
            });
        } else {
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
      //if image exists and no video source
    } else if (withImage && !Object.keys(this.state.video_source).length) {
      //loop through video sources
      for (let i = 0; i < this.state.video_sources.length; i++) {
        //let createdAt = Date.parse(this.state.video_sources[i].createdAt);
        //let filter = Date.parse('2021-07-27T00:00:00.000000');
        let filename = '';

        if (
          this.state.video_sources[i].label.includes('✅')
          //&& createdAt > filter
        ) {
          filename = this.state.video_sources[i].label;
          filename = filename.replace(' ✅', '');
          filename = filename.replace('_IR_audiomp4', '');
          filename = filename.replace('_RGB_audiomp4', '');
          filename = filename.replace('.mov', '');
          filename = filename.replace('.mp4', '');
          filename = filename + '_' + this.state.video_sources[i].duration;
          filename = filename.slice(0, 30);

          let frames = await backend.getFrames(
            this.state.video_sources[i].video_id
          );
          let temp_total_frames = {};

          frames.forEach((f) => {
            specialCases.forEach(
              (prop) => (f[`${prop}_changed`] = f[prop] > 0)
            );
            temp_total_frames[f.frame] = JSON.parse(JSON.stringify(f));
          });

          rows = this.parseFrameData(temp_total_frames);
          //save data to csv
          let wb = XLSX.utils.book_new();
          wb.SheetNames.push(filename);
          wb.Sheets[filename] = XLSX.utils.json_to_sheet(rows);
          if (wb.SheetNames.length > 0) {
            const fileType = 'text/csv';
            const excelBuffer = XLSX.write(wb, {
              bookType: 'csv',
              type: 'array',
            });
            let data = new Blob([excelBuffer], { type: fileType });
            let formData = new FormData();
            formData.append('file', data, filename + '.csv');

            //const uri = `https://test.realitydetector.com:81/extract_video_labelling_frame/${this.state.video_sources[i].video_id}`;
            const uri = `${process.env.REACT_APP_ENGINE_URL}/videos/extract_video_labelling_frame/${this.state.video_sources[i].video_id}`;

            await axios
              .post(
                uri,
                formData,
                { responseType: 'blob' }
              )
              .then((response) => {
                if (response.data) {
                  FileSaver.saveAs(response.data, filename + '.zip');
                } else {
                  throw Error(
                    `Server returned ${response.status}: ${response.statusText}`
                  );
                }
              })
              .catch((error) => {
                alert(error);
                this.setState({ loading: false });
              });
          }
        }
      }
      this.setState({ loading: false });
    } else {
      if (rows.length > 1) {
        this.saveDataToFile(filename, rows);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  };

  saveDataToFile = (filename, rows) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(filename);
    wb.Sheets[filename] = XLSX.utils.json_to_sheet(rows);
    if (wb.SheetNames.length > 0) {
      const fileType = 'text/csv';
      const excelBuffer = XLSX.write(wb, {
        bookType: 'csv',
        type: 'array',
      });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, filename + '.csv');
    }
  };

  parseFrameData = (frames_source) => {
    let rows = [];
    // keep track of previous values of special cases
    let previousValues = {};
    specialCases.forEach((c) => (previousValues[c] = '-'));

    Object.entries(frames_source).forEach(([index, frame]) => {
      let row = this.createData(parseInt(index));
      Object.entries(frame).forEach(([property, label]) => {
        if (specialCases.includes(property)) {
          // use previous values if there is no current value
          if (label > 0) previousValues[property] = frame[property];
          row[property] = previousValues[property];
        } else if (
          property === 'ufid' ||
          property === 'uvid' ||
          property.includes('changed')
        ) {
          delete row[property];
        } else if (label !== 'None') {
          row[property] = label;
        }
      });
      let rowValue = Object.values(row);
      let rowIn = false;
      for (let rv = 0; rv < rowValue.length; rv++) {
        if (rowIn) {
          break;
        }
        for (let i = 1; i < pressStates.length; i++) {
          if (rowValue[rv] === pressStates[i]) {
            rows.push(row);
            rowIn = true;
            break;
          }
        }
        for (let i = 1; i < deceptionStates.length; i++) {
          if (rowValue[rv] === deceptionStates[i]) {
            rows.push(row);
            rowIn = true;
            break;
          }
        }
      }
    });
    return rows;
  };
  downloadAllCsv = async () => {
    if (this.state.video_sources.length === 0) return;

    this.setState({ loading: true });

    // in case there are any unsaved data
    await this.saveData();
    const zip = require('jszip')();
    //loop through video sources
    for (let i = 0; i < this.state.video_sources.length; i++) {
      if (this.state.video_sources[i].label.includes('✅')) {
        let rows = [];
        let filename = this.state.video_sources[i].label;
        filename = filename.replace(' ✅', '');
        filename = filename.replace('_IR_audiomp4', '');
        filename = filename.replace('_RGB_audiomp4', '');
        filename = filename.replace('.mov', '');
        filename = filename.replace('.mp4', '');
        filename = filename + '_' + this.state.video_sources[i].duration;
        filename = filename.slice(0, 30);
        let frames = await backend.getFrames(
          this.state.video_sources[i].video_id
        );
        let temp_total_frames = {};

        frames.forEach((f) => {
          specialCases.forEach((prop) => (f[`${prop}_changed`] = f[prop] > 0));
          temp_total_frames[f.frame] = JSON.parse(JSON.stringify(f));
        });

        rows = this.parseFrameData(temp_total_frames);
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push(filename);
        wb.Sheets[filename] = XLSX.utils.json_to_sheet(rows);
        if (wb.SheetNames.length > 0) {
          const fileType = 'text/csv';
          const excelBuffer = XLSX.write(wb, {
            bookType: 'csv',
            type: 'array',
          });
          let data = new Blob([excelBuffer], { type: fileType });
          zip.file(filename + '.csv', data);
        }
      }
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, 'labeled_csv.zip');
    });
    this.setState({ loading: false });
  };

  uploadProcessingCsv = (event) => {
    if (this.state.video_sources.length === 0) return;

    this.setState({ loading: true });
    let header = true;
    var csvData = {};
    Papa.parse(event.target.files[0], {
      worker: true,
      step: function (results) {
        if (header) {
          results.data.forEach((data) => (csvData[data] = []));
          header = false;
        } else {
          for (let i = 0; i < results.data.length; i++) {
            var key = Object.keys(csvData)[i];
            csvData[key].push(results.data[i]);
          }
        }
      },
    });

    this.setState({ processing_data: csvData });
    // in case there are any unsaved data

    this.setState({ loading: false });
  };

  // ===================================== VIDEO FUNCTIONS ===================================== //

  selectVideo = async (video) => {
    if (video.label) video.label = video.label.replace(' ✅', '');
    if (this.state.video_source.name === video.label) return;
    this.setState({ loading: true, video_ready: false });
    // save any unsaved changes
    // keep track of lastFrameNumber for current video before switching
    await this.saveData();
    let videos = this.state.video_sources;
    let currentVideo =
      videos[
        videos.findIndex((v) => v.video_id === this.state.video_source.video_id)
      ];

    if (currentVideo) {
      currentVideo.lastFrameNumber = this.getFrameNumber();
    }

    this.setState({ video_sources: videos });
    
    startTime = 0;
    frameOffset = 0;
    timeOffset = 0;
    // get data of selected video
    let fps = await axios
      .get(process.env.REACT_APP_ENGINE_URL + '/videos/get_fps/' + video.video_id)
      .then((response) => response.data.frame_rate);

    this.setState({ fps: 30 });
    if (fps) {
      this.setState({ fps: fps });
    }
    await this.getVideoDetails(video.video_id);
    await this.getVideoFrames(video.video_id);

    this.setState({ loading: false }, () => {
      this.renderDataPanel();

      const lastFrameNumber = this.state.video_sources.find(
        (e) => e.video_id === video.video_id
      ).lastFrameNumber;
      if (lastFrameNumber) this.player.seekTo(0 / this.state.fps, 'seconds');
    });
  };

  // ===================================== VIDEO PLAYBACK FUNCTIONS ===================================== //

  onBuffer = () => {
    // focus on left-container so that labeller can pause video using spacebar/enter key
    document.getElementById('left-container').focus();
    this.setState({ video_loading: true });
  };

  onBufferEnd = () => {
    this.setState({ video_loading: false });
  };

  onSeek = () => {
    console.log('on seek');
    let adjustedTime = this.toNearestFrame(this.state.current_mediatime);
    console.log(adjustedTime);
    let adjustedFps = adjustedTime * this.state.fps;
    // to prevent infinite loop
    if (adjustedFps !== Math.round(adjustedFps)) {
      this.player.seekTo(adjustedTime, 'seconds');
    }
    this.setState({ video_seeking: false });
  };

  onProgress = (props) => {
    document.getElementById('percentage').innerHTML = `${(
      (props.playedSeconds * 100) /
      this.player.getDuration()
    ).toFixed(1)} %`;
  };

  onEnded = () => {
    this.setState({ paused: true });
  };

  onSearch = (event) => {
    if (!this.player.getInternalPlayer()) return;

    const id = event.currentTarget.id;
    const value = event.currentTarget.value;
    let seekValue = id === 'frame-search' ? value / this.state.fps : value;
    seekValue = this.toNearestFrame(seekValue);
    //add 0.02 seconds for more accurate seeking
    seekValue += timeOffset + 0.02;
    console.log(seekValue);
    this.player.seekTo(seekValue, 'seconds');
  };

  onScrub = (frameChange) => {
    if (!this.player.getInternalPlayer()) return;
    if (!this.player.getInternalPlayer().paused) return;
    this.setState({ frame_processing: true });
    if (frameChange > 0) {
      this.player.getInternalPlayer().currentTime += (Math.abs(frameChange) / this.state.fps);
    } else if (frameChange < 0) {
      this.player.getInternalPlayer().currentTime -= (Math.abs(frameChange) / this.state.fps);
    }
    // convert seconds to frames
    //let newTime = (this.getFrameNumber() + frameChange) / fps;

    // ensure time is between 0 and duration of video
    //newTime = Math.max(0, Math.min(newTime, this.player.getDuration()));
    //this.player.seekTo(newTime, 'seconds');
  };

  togglePlayPause = (pb_rate) => {
    const video = this.player.getInternalPlayer();
    // const myAudio = document.getElementById('audio');
    if (video) {
      if (!video.paused) {
        video.pause();
        // myAudio.pause();
        let adjustedTime = this.toNearestFrame(this.state.current_mediatime);
        this.player.seekTo(adjustedTime, 'seconds');
        this.setState({ paused: true, video_loading: false });
      } else {
        this.setState(
          {
            paused: false,
            playback_rate: pb_rate,
          },
          () => {
            video.play();
            // myAudio.play();
          }
        );
      }
    }
  };

  toggleSound = () => {
    this.setState({ volume: this.state.volume === 0 ? 1 : 0 });
  };

  videoKeyControls = (event) => {
    if (document.activeElement === document.getElementById('search-ui')) return;
    event.stopPropagation();

    const toggleFrameKeys = ['ArrowRight', 'ArrowLeft'];
    const playPauseKeys = [' ', 'Enter'];

    if (toggleFrameKeys.includes(event.key)) {
      event.preventDefault();
      if (!this.state.frame_processing) {
        const frameChange =
          event.key === 'ArrowRight'
            ? event.ctrlKey
              ? 5
              : 1
            : event.ctrlKey
            ? -5
            : -1;
        this.onScrub(frameChange);
        // flash corresponding scrubber button for labeller
        const elem = document.getElementById(`scrubber${frameChange}`);
        elem.style.borderColor = 'white';
        setTimeout(() => (elem.style.borderColor = 'transparent'), 150);
      }
    } else if (playPauseKeys.includes(event.key)) {
      event.preventDefault();
      const pb_rate = event.ctrlKey ? 0.5 : 1;
      this.togglePlayPause(pb_rate);
      // flash corresponding scrubber button for labeller
      const elem = document.getElementById(`playpause${pb_rate}`);
      elem.style.borderColor = 'white';
      setTimeout(() => (elem.style.borderColor = 'transparent'), 150);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.onZoom(1);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.onZoom(-1);
    }
  };

  setVideoReady = async () => {
    if (!this.state.video_ready) {
      this.setState({ video_ready: true });
      this.player
        .getInternalPlayer()
        .requestVideoFrameCallback(this.updateVideoFrame);
      this.onScrub(1);
    }
  };

  // ===================================== VIDEO ZOOM FUNCTIONS ===================================== //

  toggleCanvasDisplay = (display) => {
    this.setState({ video_zoom: display }, () => {
      if (display) {
        // mouse enter video
        document.getElementById('video-player').focus();
      } else {
        // mouse exit video
        document.getElementById('video-player').blur();
        clearInterval(canvas_interval);
      }
    });
  };

  onZoom = (mag) => {
    if (mag > 0) {
      // scroll up
      this.setState({
        video_zoom_index: Math.min(
          this.state.video_zoom_index + 1,
          zoomStates.length - 1
        ),
      });
    } else if (mag < 0) {
      // scroll down
      this.setState({
        video_zoom_index: Math.max(this.state.video_zoom_index - 1, 0),
      });
    }
  };

  showZoomCanvas = (event) => {
    const video = this.player.getInternalPlayer();
    const bounds = video.getBoundingClientRect();
    const cursorX = event.clientX - bounds.left;
    const cursorY = event.clientY - bounds.top;
    const canvas = document.getElementById('zoom-canvas');
    const zoomMag = zoomStates[this.state.video_zoom_index];

    const swidth = canvas.clientWidth / zoomMag;
    const sheight = canvas.clientHeight / zoomMag;

    // video cropping parameters (trial and error)
    let cropX =
      ((cursorX - swidth / 2) * 1.73 * (2 / zoomMag) ** 0.2 * 1275) /
      video.clientWidth;
    let cropY =
      ((cursorY - sheight / 2) * 1.73 * (2 / zoomMag) ** 0.2 * 750) /
      video.clientHeight;
    // handle boundary conditions
    cropX = Math.max(0, cropX);
    cropY = Math.max(0, cropY);

    // position of canvas
    let posx = cursorX - canvas.clientWidth / 2;
    let posy = cursorY - canvas.clientHeight / 2;
    // handle boundary conditions
    posx = Math.max(0, posx);
    posx =
      Math.min(video.clientWidth, posx + canvas.clientWidth) -
      canvas.clientWidth;
    posy = Math.max(0, posy);
    posy =
      Math.min(video.clientHeight, posy + canvas.clientHeight) -
      canvas.clientHeight;
    // position canvas directly where cursor is
    canvas.style.marginLeft = posx.toString() + 'px';
    canvas.style.marginTop = posy.toString() + 'px';

    canvas.getContext('2d').drawImage(
      video,
      cropX,
      cropY,
      swidth,
      sheight, // video element, position and size of video to crop
      0,
      0,
      canvas.clientWidth,
      canvas.clientHeight // position and size of image to draw
    );

    // continue showing zoomed video after mouse stops moving
    clearTimeout(canvas_interval);
    event.persist();
    canvas_interval = setTimeout(() => this.showZoomCanvas(event));
  };

  // ===================================== FRAME FUNCTIONS ===================================== //

  getFrameNumber = () => {
    if (this.player.getInternalPlayer()) {
      //return Math.round(this.player.getCurrentTime() * fps);
      return this.state.current_frame;
    }
    return 0;
  };

  defaultFrame = (purpose, frameNumber) => {
    let defaultLabels = {};
    elements
      .map((e) => e[1])
      .forEach((prop) => {
        if (specialCases.includes(prop)) {
          defaultLabels[prop] = 0;
          // used to track changes in properties that have integer/float values (won't be sent to database)
          if (purpose === 'toDisplay') defaultLabels[`${prop}_changed`] = false;
        } else {
          defaultLabels[prop] = 'None';
        }
      });

    // if (purpose === "toDB") {
    defaultLabels['uvid'] = this.state.video_source.video_id;
    defaultLabels['time'] = frameNumber / this.state.fps;
    defaultLabels['frame'] = parseInt(frameNumber);
    // }

    return JSON.parse(JSON.stringify(defaultLabels));
  };

  updateFrameLabels = (property, action, changed, frameNumber = null) => {
    if (!frameNumber) frameNumber = this.getFrameNumber();
    //check for if current frame is negative
    if (frameNumber < 0) {
      alert('Negative frames detected. Please refresh and try again');
      return;
    }
    // UPDATE FRAMES TO DISPLAY
    let newLabels =
      total_frames[frameNumber] || this.defaultFrame('toDisplay', frameNumber);
    newLabels[property] = action;
    if (specialCases.includes(property))
      newLabels[`${property}_changed`] = changed;
    total_frames[frameNumber] = newLabels;
    this.renderDataPanel();
    backend.displayStatus(backend.message.changes);

    // UPDATE FRAMES TO DB
    if (!unsaved_frames[frameNumber]) {
      if (newLabels.ufid) {
        unsaved_frames[frameNumber] = {
          ufid: newLabels.ufid,
          uvid: this.state.video_source.video_id,
          frame: frameNumber,
        };
      } else {
        unsaved_frames[frameNumber] = this.defaultFrame('toDB', frameNumber);
      }
    }
    unsaved_frames[frameNumber][property] = action;
  };

  // ===================================== RENDER FUNCTIONS ===================================== //

  renderLabellingPanel = (frameNumber) => {
    const labels = total_frames[frameNumber];
    const cats = Object.values(categories);

    // show red dot if there are interesting labels in category
    for (let i in cats) {
      const c = cats[i];
      document.getElementById(`${c}-indicator`).style.display = 'none';

      if (!labels) continue; // if frame is not editable or doesn't exist

      const cat_elements = elements.filter((e) => e[0] === c);
      for (let j in cat_elements) {
        const elem = cat_elements[j][1];
        if (
          labels[`${elem}_changed`] ||
          pressStates.slice(1).includes(labels[elem])
        ) {
          document.getElementById(`${c}-indicator`).style.display = 'block';
          break;
        }
      }
    }

    // show label details within current category
    this.datalabellingElement
      .filter((e) => e[0] === this.state.current_category)
      .forEach((e) => e[1].current.displayAnnotations(frameNumber));
  };

  renderDisplayedProcessingData = (frameNumber) => {
    if (Object.keys(this.state.processing_data).length !== 0) {
      let displayObj = {};
      for (var header in this.state.processing_data) {
        if (this.state.processing_data[header][frameNumber]) {
          displayObj[header] = this.state.processing_data[header][frameNumber];
        }
      }
      this.setState({ current_displayed_processing_data: displayObj });
    }
  };

  renderDataPanel = () => {
    let rows = [];

    // keep track of previous values of special cases
    let previousValues = {};
    specialCases.forEach((c) => (previousValues[c] = 0));

    Object.entries(total_frames).forEach(([index, frame]) => {
      // choose relevant properties from current category
      let row = Object.keys(frame)
        .filter((property) =>
          elements
            .filter((e) => e[0] === this.state.current_category)
            .map((e) => e[1])
            .includes(property)
        )
        .reduce((obj, key) => {
          obj[key] = frame[key];
          return obj;
        }, {});

      // choose only interesting frames
      if (Object.values(row).find((label) => label !== 'None' && label !== 0)) {
        row['frame'] = parseInt(index);

        // update latest values for special cases
        specialCases.forEach((c) => {
          if (row[c] !== undefined) {
            previousValues[c] = frame[`${c}_changed`]
              ? row[c]
              : previousValues[c];
            row[c] = previousValues[c].toString();
          }
        });

        rows.push(row);
      }
    });
    this.setState({ rows: rows });
  };

  async refresh() {
    await this.getVideoFrames(this.state.video_source.video_id);
    this.renderDataPanel();
  }

  render() {
    return (
      <div className="App">
        <div
          id="loading"
          style={{ display: this.state.loading ? 'block' : 'none' }}
        >
          <ReactLoading
            type="spin"
            height={'20%'}
            width={'20%'}
            style={loadingSpinnerStyle}
          />
        </div>
        <Logout/>
        { this.state.admin && <Admin/>}
        {/* <button className='logo' style={{ outline: "none" }} onClick={() => {this.setState({ logo_dropdown: !this.state.logo_dropdown})}}>
          <img id='logo_svg' src={logo} alt="logo"/>
          <p id='logo_text'>Reality Detector</p>
        </button>

        <div className={this.state.logo_dropdown ? "logo_dropdown" : "hide"}>
          <button className="logo_dropdown_button" value="dataLabelling" onClick={this.changePage}> Data Labelling Tool </button>
          <button className="logo_dropdown_button" value="settings" onClick={this.changePage}> Settings </button>
        </div> */}

        <ResponsiveGridLayout
          className="layout"
          style={{ width: '99.5%', margin: '0 auto' }}
          layouts={{ lg: this.state.layout }}
          isDraggable={false}
          compactType="horizontal"
          breakpoints={{ lg: 1200 }}
          cols={{ lg: gridResolution }}
          onResize={(layout) => {
            layout[1].w = gridResolution - layout[0].w;
            layout[1].x = layout[0].w;
          }}
          onLayoutChange={(layout) => {
            // trigger componentDidUpdate function to resize responsive grid and fit its content
            this.setState({ layout: layout });
          }}
        >
          <div
            tabIndex="0"
            onKeyDown={this.videoKeyControls}
            id="left-container"
            key="left-container"
            style={{
              height: '100%',
              backgroundColor: '#cdcfd1',
              outline: 'none',
            }}
          >
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
              {/* <FormControl style={{ padding: "10px 0.5% 10px 1%", flex: "1 0 auto" }}>
                Environment:
                <Select
                  value={this.state.current_env}
                  placeholder={db_envs.filter(e=>e.value===this.state.current_env)[0].label}
                  options={db_envs}
                  onChange={this.selectEnvironment}
                />
              </FormControl> */}
              <FormControl
                style={{ padding: '10px 1% 10px 0.5%', flex: '4 0 auto' }}
              >
                Videos:
                <Select
                  value={this.state.video_source.name}
                  placeholder={`${this.state.video_source.name}${
                    this.state.video_source.dv_cycle_status === 'labelled'
                      ? ' ✅'
                      : ''
                  }`}
                  options={this.state.video_sources}
                  onChange={this.selectVideo}
                />
              </FormControl>
            </div>

            <div
              style={{
                display: this.state.video_source.url ? 'grid' : 'none',
                justifyContent: 'center',
              }}
            >
              <TransformWrapper>
                <TransformComponent>
                  <div
                    id="video-player"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <ReactPlayer
                      ref={(player) => (this.player = player)}
                      volume={this.state.volume}
                      height="100%"
                      width="100%"
                      style={{ outline: 'none' }}
                      progressInterval={1000 / this.state.fps}
                      playbackRate={this.state.playback_rate}
                      onBuffer={this.onBuffer}
                      onBufferEnd={this.onBufferEnd}
                      //onSeek={this.onSeek}
                      onProgress={this.onProgress}
                      onEnded={this.onEnded}
                      onReady={this.setVideoReady}
                      controls={true}
                      // onMouseEnter={() => {this.toggleCanvasDisplay(true)}}
                      // onMouseLeave={() => {this.toggleCanvasDisplay(false)}}
                      // onMouseMove={this.showZoomCanvas.bind(this)}
                      config={{
                        file: {
                          attributes: {
                            crossOrigin: 'anonymous',
                            id: 'react-player',
                            controlsList: 'nodownload',
                          },
                        },
                      }}
                      url={this.state.video_source.url}
                    />
                  </div>
                  {this.state.video_ready && (
                    <div id="audio-wrapper" style={{ position: 'absolute' }}>
                      <AudioSpectrum
                        audioId={'react-player'}
                        height={200}
                        width={200}
                        capColor={'red'}
                        capHeight={2}
                        meterWidth={2}
                        meterCount={512}
                        meterColor={[
                          { stop: 0, color: '#f00' },
                          { stop: 0.5, color: '#0CD7FD' },
                          { stop: 1, color: 'red' },
                        ]}
                        gap={4}
                      />
                    </div>
                  )}
                  {Object.keys(this.state.current_displayed_processing_data)
                    .length !== 0 && (
                    <div
                      id="data-overlay"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        padding: '10px',
                        backgroundColor: '#00000057',
                      }}
                    >
                      <DataOverlay
                        current_displayed_processing_data={
                          this.state.current_displayed_processing_data
                        }
                      />
                    </div>
                  )}
                </TransformComponent>
              </TransformWrapper>

              <div
                id="video-loading"
                style={{ display: this.state.video_loading ? 'block' : 'none' }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  height={'20%'}
                  width={'20%'}
                  style={loadingSpinnerStyle}
                />
              </div>

              {/* <div id="zoom-controls">
                <div id="zoom-state">
                  <p> Zoom: {zoomStates[this.state.video_zoom_index].toFixed(2)} x </p>
                </div>
                <Button onClick={() => this.onZoom(1)}><ZoomInIcon style={{ fontSize: '30px' }}/></Button>
                <Button onClick={() => this.onZoom(-1)}><ZoomOutIcon style={{ fontSize: '30px' }}/></Button>
              </div> */}

              {this.state.video_zoom && this.state.video_zoom_index > 0 ? (
                <canvas id="zoom-canvas" style={{ display: 'block' }} />
              ) : (
                ''
              )}

              <Scrubber
                volume={this.state.volume}
                paused={this.state.paused}
                playback_rate={this.state.playback_rate}
                toggleSound={this.toggleSound}
                togglePlayPause={this.togglePlayPause}
                onScrub={this.onScrub}
                onSearch={this.onSearch}
              />
            </div>

            <LabellingDashboard
              player={this.player}
              paused={this.state.paused}
              total_frames={total_frames}
              refs={this.datalabellingElement.map((e) => e[1])}
              current_category={this.state.current_category}
              fps={this.state.fps}
              current_frame={this.state.current_frame}
              current_mediatime={this.state.current_mediatime}
              changeCategory={this.changeCategory}
              videoKeyControls={this.videoKeyControls}
              updateFrameLabels={this.updateFrameLabels}
            />
          </div>

          <div
            key="right-container"
            style={{ height: '100%', backgroundColor: '#cdcfd1' }}
          >
            <DataRetrospective
              video={this.player}
              current_category={this.state.current_category}
              rowTitles={elements
                .filter((e) => e[0] === this.state.current_category)
                .map((e) => e[1])}
              rows={this.state.rows}
              downloadCSV={this.downloadCSV}
              downloadAllCsv={this.downloadAllCsv}
              uploadProcessingCsv={this.uploadProcessingCsv}
              video_source={this.state.video_source}
              updateVideoStatus={this.updateVideoStatus}
              refresh={this.refresh.bind(this)}
            />
          </div>
        </ResponsiveGridLayout>
      </div>
    );
  }
}

export default LabellingTool;
