import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

// Import component
import { CollapsibleTable } from './CollapsibleTable.js';

function TabPanel(props) {
  return (
    <Typography component="div">
      <Box p={1.5}>{props.children}</Box>
    </Typography>
  );
}
TabPanel.propTypes = { children: PropTypes.node };

class DataRetrospective extends React.Component {
  render() {
    return (
      <div>
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={this.props.current_category.split('_').join(' ')} />
            </Tabs>
          </AppBar>
          <TabPanel>
            <CollapsibleTable
              video={this.props.video}
              rowTitles={this.props.rowTitles}
              rows={this.props.rows}
              downloadCSV={this.props.downloadCSV}
              downloadAllCsv={this.props.downloadAllCsv}
              uploadProcessingCsv={this.props.uploadProcessingCsv}
              video_source={this.props.video_source}
              updateVideoStatus={this.props.updateVideoStatus}
              refresh={this.props.refresh.bind(this)}
            />
          </TabPanel>
        </div>
      </div>
    );
  }
}

export default DataRetrospective;
