// Core
import React from 'react';

class LoggedIn extends React.Component {
  render() {
    return (
        <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h3>Check your email</h3>
        <p style={{color:"black"}}>We sent a magic link to {this.props.email}
          <br/>
          Click on it to confirm the log in.
        </p>
      </div>
    );
  }
}

export default LoggedIn;
