import { Store } from "pullstate";

export const LoginStore = new Store({
  token: "",
  email: "",
});

LoginStore.subscribe(
    state => state.token, // state to watch
    token => localStorage.setItem('token', token), // callback to trigger on change
  );

// Rehydration script for react
export const rehydrateStore = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        LoginStore.update(s => {
            s.token = token;
          })
      }
    } catch (e) {
      console.log('Error occured: ' + e);
    }
  };
  