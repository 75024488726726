// Core
import React from 'react';
import DataLabelling from './DataLabelling';
import './LabellingDashboard.css';

// UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

// Others
import { elements } from '../../config';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ backgroundColor: '#595959', padding: '4px' }}
      hidden={value !== index}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

class LabellingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      occurrences: {},
      results: [],
    };
  }

  searchElements = (event) => {
    const searchText = event.currentTarget.value
      .toLowerCase()
      .trim()
      .split(' ')
      .join('_')
      .replace('&', 'and');
    setTimeout(() => {
      if (searchText === '') {
        this.setState({ occurrences: {}, results: [] });
      } else {
        const filteredElements = elements.filter((e) =>
          e[1].toLowerCase().trim().includes(searchText)
        );
        const occurrences = filteredElements
          .map((e) => e[0])
          .reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {});
        const cat_occurrences = Object.keys(occurrences);
        if (!cat_occurrences.includes(this.props.current_category)) {
          if (cat_occurrences.length > 0) {
            this.props.changeCategory(cat_occurrences[0]);
          }
        }
        this.setState({
          occurrences: occurrences,
          results: filteredElements.map((e) => e[1]),
        });
      }
    }, 0);
  };

  render() {
    let dispCategories = {};
    for (const i in elements) {
      const [cat, elem] = elements[i];
      if (cat in dispCategories) dispCategories[cat].push([i, elem]);
      else dispCategories[cat] = [[i, elem]];
    }

    return (
      <div id="manual-labelling-dashboard">
        <div style={{ display: 'flex' }}>
          <h2 style={{ width: '65%' }}>Manual Labelling Dashboard</h2>
          <TextField
            id="search-ui"
            disabled={!this.props.paused}
            label="Search AUs"
            inputProps={{ style: { textAlign: 'center' } }}
            InputLabelProps={{ style: { padding: '5px' } }}
            style={{
              width: '35%',
              margin: 'auto',
              height: 46,
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
            onChange={this.searchElements}
          />
        </div>
        <div className="labelling_framecounter">
          <h3 id="frame_number" style={{ flexBasis: '150px' }}>
            Frame: {this.props.current_frame}
          </h3>
          <h3 id="timestamp" style={{ flexBasis: '180px' }}>
            Time: {this.props.current_mediatime.toFixed(5)} s
          </h3>
          <h3 id="percentage" style={{ flexBasis: '180px' }}>
            0.0 %
          </h3>
          <h3 id="frame_rate" style={{ flexBasis: '180px' }}>
            Frame Rate: {this.props.fps}/s
          </h3>
          <h3 id="status"></h3>
        </div>
        <div className="categories-container">
          <div className="categories">
            {Object.keys(dispCategories).map((cat) => {
              return (
                <div
                  key={cat}
                  id={`${cat}-button`}
                  style={{ display: 'inline-block', position: 'relative' }}
                >
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 0,
                      padding: '6px 26px 6px 26px',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '4px',
                      color:
                        this.props.current_category === cat
                          ? '#3F51B5'
                          : 'black',
                      borderBottomColor:
                        this.props.current_category === cat
                          ? '#3F51B5'
                          : 'transparent',
                    }}
                    disableElevation
                    onClick={() => {
                      if (cat !== this.props.current_category)
                        this.props.changeCategory(cat);
                    }}
                  >
                    <strong>{cat.split('_').join(' ')}</strong>
                  </Button>
                  <div
                    className="search-result-indicator"
                    style={{
                      display: this.state.occurrences[cat] ? 'block' : 'none',
                    }}
                  >
                    {this.state.occurrences[cat]}
                  </div>
                  <div
                    id={`${cat}-indicator`}
                    className="interesting-frame-indicator"
                    style={{ display: 'none' }}
                  ></div>
                </div>
              );
            })}
          </div>

          {Object.keys(dispCategories).map((cat) => {
            const ElemInCategory = dispCategories[cat];

            return (
              <TabPanel
                key={cat}
                index={cat}
                value={this.props.current_category}
              >
                <div className="manual-labelling-actions-container">
                  {ElemInCategory.map(([id, element], index) => {
                    return (
                      <DataLabelling
                        key={index}
                        searched={this.state.results.includes(element)}
                        ref={this.props.refs[id]}
                        property={element}
                        player={this.props.player}
                        elements={ElemInCategory}
                        total_frames={this.props.total_frames}
                        videoKeyControls={this.props.videoKeyControls}
                        updateFrameLabels={this.props.updateFrameLabels}
                      />
                    );
                  })}
                </div>
              </TabPanel>
            );
          })}
        </div>
      </div>
    );
  }
}

export default LabellingDashboard;
