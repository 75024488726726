import axios from 'axios';
import React, { useCallback, useState } from 'react';
// Components
import {
  Navigate
} from "react-router-dom";
import LoggedIn from './components/Login/LoggedIn';
import LoginField from './components/Login/LoginField';
import { LoginStore } from "./LoginStore";

function Login (props){
  const [email, setEmail] = useState("");
  const [signIn, setSignIn] = useState(false);
  const tokenExist = LoginStore.useState(s => s.token)

  const emailTextChange = useCallback((e) => {
    setEmail(e.target.value)
  }, []);

  const sendEmail = useCallback((email) => {
    axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/login/', { destination: email })
    .then((response) => {
      setSignIn(true)
      LoginStore.update(s => {
            s.email = email;
      })
    })
    .catch((error) => {
      alert(error);
    });
  }, []);

  
    return (
      <>
        {tokenExist ? <Navigate to="/" /> : <></>}
        {signIn
          ? <LoggedIn email={email} />
          : <LoginField email={email} emailTextChange={emailTextChange} sendEmail={() => sendEmail(email)} />
        }
      </>
    );
}


export default Login;
