import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginStore } from "../../LoginStore";



function HandleTokenCallBackPage(){
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate()
  LoginStore.update(s => {
    s.token = searchParams.get('token');
  })
  const token = LoginStore.useState(s => s.token);
  
  useEffect(() => {
    // Update the document title using the browser API
    console.log("use effect")
    if(token) return navigate("/"); 
  }, []);
  
    return (
        <>
        <h2>Redirecting you...</h2>
        </>
      );
}


export default HandleTokenCallBackPage;
