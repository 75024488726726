export const scrubStyle = {
  fontSize: 17,
  borderRadius: 0,
  borderWidth: '3px',
  borderStyle: 'solid',
  borderColor: 'transparent',
};
export const loadingSpinnerStyle = {
  position: 'relative',
  top: '47.5%',
  left: '47.5%',
  width: '5%',
  opacity: 0.8,
};

export const zoomStates = [1, 1.2, 1.4, 1.6];
export const pressStates = [
  'None',
  'Onset',
  'Onset(U)',
  'Apex-A',
  'Apex-B',
  'Apex-C',
  'Apex-D',
  'Apex-E',
  'Offset',
];
export const deceptionStates = ['None', 'Start', 'End'];
export const pressStateColors = {
  None: null,
  Onset: '#ed6f00',
  'Onset(U)': '#ed6f00',
  'Apex-A': '#fc565e',
  'Apex-B': '#f04149',
  'Apex-C': '#e32932',
  'Apex-D': '#d1111b',
  'Apex-E': '#b5020b',
  Offset: '#ed6f00',
  Start: '#ed6f00',
  End: '#ed6f00',
};
export const numberStates = [];
for (let i = 0; i < 400; i++) numberStates.push(i * 0.5);

// export const categories = {
//   upperFace: "upper",//"upper_face",
//   lowerFaceUpDown: "lower_(up_down)",//"lower_face_up_down",
//   lowerFaceHorizontal: "lower_(horizontal)",//"lower_face_horizontal",
//   lowerFaceOblique: "lower_(oblique)",//"lower_face_oblique",
//   lowerFaceOrbital: "lower_(orbital)",//"lower_face_orbital",
//   head: "head",
//   eye: "eye",
//   other: "others",
// }
// export const elements = [
//   [categories.upperFace, "brow_lower"],
//   [categories.upperFace, "inner_brow_raise"],
//   [categories.upperFace, "outer_brow_raise"],
//   [categories.upperFace, "upper_lid_raise"],
//   [categories.upperFace, "lid_tighten"],
//   [categories.upperFace, "cheek_raise_and_lid_compress"],
//   [categories.upperFace, "eye_closure"],
//   [categories.upperFace, "blink"],
//   [categories.upperFace, "wink"],

//   [categories.lowerFaceUpDown, "nose_wrinkle"],
//   [categories.lowerFaceUpDown, "upper_lip_raise"],
//   [categories.lowerFaceUpDown, "chin_raise"],
//   [categories.lowerFaceUpDown, "lip_corner_depress"],
//   [categories.lowerFaceUpDown, "lips_part"],
//   [categories.lowerFaceUpDown, "jaw_drop"],
//   [categories.lowerFaceUpDown, "mouth_stretch"],

//   [categories.lowerFaceHorizontal, "lip_stretch"],
//   [categories.lowerFaceHorizontal, "dimple"],

//   [categories.lowerFaceOblique, "nasolabial_furrow_deepen"],
//   [categories.lowerFaceOblique, "lip_corner_pull"],
//   [categories.lowerFaceOblique, "sharp_lip_pull"],

//   [categories.lowerFaceOrbital, "lip_puck"],
//   [categories.lowerFaceOrbital, "lip_funnel"],
//   [categories.lowerFaceOrbital, "lip_tighten"],
//   [categories.lowerFaceOrbital, "lip_press"],
//   [categories.lowerFaceOrbital, "lips_suck"],

//   [categories.head, "head_turn_left"],
//   [categories.head, "head_turn_right"],
//   [categories.head, "head_up"],
//   [categories.head, "head_down"],
//   [categories.head, "head_tilt_left"],
//   [categories.head, "head_tilt_right"],
//   [categories.head, "head_forward"],
//   [categories.head, "head_back"],

//   [categories.eye, "eyes_turn_left"],
//   [categories.eye, "eyes_turn_right"],
//   [categories.eye, "eyes_up"],
//   [categories.eye, "eyes_down"],
//   [categories.eye, "walleye"],
//   [categories.eye, "cross_eye"],

//   // [categories.other, "blink"],
//   // [categories.other, "lip_press"],
//   [categories.other, "mouth_shrug"],
//   [categories.other, "pupil_change"],
//   // [categories.other, "chin_raise"],
//   [categories.other, "shoulder_shrug"],
//   [categories.other, "heart_rate"],
//   [categories.other, "respiration_rate"]
// ];

export const categories = {
  deception: 'deception',
  audio: 'audio',
  other: 'other',
};
export const elements = [
  [categories.deception, 'blink'],
  [categories.deception, 'brow_lower'],
  [categories.deception, 'inner_brow_raise'],
  [categories.deception, 'outer_brow_raise'],
  [categories.deception, 'upper_lid_raise'],
  [categories.deception, 'lid_tighten'],
  [categories.deception, 'cheek_raise_and_lid_compress'],
  [categories.deception, 'nostril_dilator'],
  [categories.deception, 'upper_lip_raise'],
  [categories.deception, 'lip_press'],
  [categories.deception, 'lip_corner_depress'],
  [categories.deception, 'lip_corner_pull'],
  [categories.deception, 'lip_tighten'],
  [categories.deception, 'chin_raise'],
  [categories.deception, 'shoulder_shrug'],
  [categories.deception, 'head_nod'],
  [categories.deception, 'head_shake'],

  [categories.audio, 'interviewer'],
  [categories.audio, 'interviewee'],
  [categories.audio, 'silence'],
  [categories.audio, 'background'],
  [categories.audio, 'deceptive'],
  [categories.audio, 'truthful'],

  [categories.other, 'eye_undefined'],
  [categories.other, 'eye_closure'],
  [categories.other, 'walleye'],
  [categories.other, 'cross_eye'],
  [categories.other, 'wink'],

  [categories.other, 'nasolabial_furrow_deepen'],
  [categories.other, 'nose_wrinkle'],
  [categories.other, 'dimple'],

  [categories.other, 'sharp_lip_pull'],
  [categories.other, 'lip_puck'],
  [categories.other, 'lip_funnel'],
  [categories.other, 'lips_suck'],
  [categories.other, 'lips_part'],
  [categories.other, 'lip_stretch'],
  [categories.other, 'mouth_stretch'],
  [categories.other, 'mouth_shrug'],
  [categories.other, 'jaw_drop'],

  [categories.other, 'head_turn_left'],
  [categories.other, 'head_turn_right'],
  [categories.other, 'head_up'],
  [categories.other, 'head_down'],
  [categories.other, 'head_tilt_left'],
  [categories.other, 'head_tilt_right'],
  [categories.other, 'head_forward'],
  [categories.other, 'head_back'],

  [categories.other, 'heart_rate'],
  [categories.other, 'respiration_rate'],
];
export const specialCases = ['heart_rate', 'respiration_rate'];
export const deceptionCases = [
  'interviewer',
  'interviewee',
  'silence',
  'background',
  'deceptive',
  'truthful',
  'head_nod',
  'head_shake',
];

export const gridResolution = 12;
export const minGridLeft = 2;
export const minGridRight = 2;

export const save_interval_ms = 10000;
export const fps = 30;
