// Core
import React from 'react';
import './Scrubber.css';

// UI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

// Others
import { scrubStyle } from '../../config';

class Scrubber extends React.Component {
  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div id="scrubber" style={{ display: 'flex' }}>
          <TextField
            type="number"
            id="frame-search"
            label="Search frame"
            disabled={!this.props.paused}
            InputLabelProps={{ style: { padding: '5px' } }}
            inputProps={{ style: { textAlign: 'center' }, min: 0 }}
            style={{ backgroundColor: 'white' }}
            onChange={this.props.onSearch}
          />
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="scrubber-5"
            disabled={!this.props.paused}
            onClick={() => this.props.onScrub(-5)}
          >
            -5
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="scrubber-1"
            disabled={!this.props.paused}
            onClick={() => this.props.onScrub(-1)}
          >
            -1
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            onClick={this.props.toggleSound}
          >
            {this.props.volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="playpause1"
            disabled={!this.props.paused && this.props.playback_rate !== 1}
            onClick={() => this.props.togglePlayPause(1)}
          >
            {this.props.paused || this.props.playback_rate !== 1 ? (
              <PlayCircleFilledIcon />
            ) : (
              <PauseCircleFilledIcon />
            )}
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="playpause0.5"
            disabled={!this.props.paused && this.props.playback_rate !== 0.5}
            onClick={() => this.props.togglePlayPause(0.5)}
          >
            {this.props.paused || this.props.playback_rate !== 0.5 ? (
              <SlowMotionVideoIcon />
            ) : (
              <PauseCircleFilledIcon />
            )}
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="scrubber1"
            disabled={!this.props.paused}
            onClick={() => this.props.onScrub(1)}
          >
            +1
          </Button>
          <Button
            style={scrubStyle}
            color="primary"
            variant="contained"
            disableElevation
            id="scrubber5"
            disabled={!this.props.paused}
            onClick={() => this.props.onScrub(5)}
          >
            +5
          </Button>
          <TextField
            type="number"
            id="time-search"
            label="Search seconds"
            disabled={!this.props.paused}
            InputLabelProps={{ style: { padding: '5px' } }}
            inputProps={{ style: { textAlign: 'center' }, min: 0 }}
            style={{ backgroundColor: 'white' }}
            onChange={this.props.onSearch}
          />
        </div>
      </div>
    );
  }
}

export default Scrubber;
