// Core
import React from 'react';

class DataOverlay extends React.Component {
  render() {
    return (
      <ul>
        {Object.keys(this.props.current_displayed_processing_data).map(
          (header, index) => (
            <li className="displayed-data" key={index}>
              {header}: {this.props.current_displayed_processing_data[header]}
            </li>
          )
        )}
      </ul>
    );
  }
}

export default DataOverlay;
