import React, { useEffect } from 'react';
import { Grid, TextField, Button, Container  } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LabellerTable from './components/Admin/LabellerTable';
import axios from 'axios';
import {
  useNavigate
} from "react-router-dom";
// Others
import ReactLoading from 'react-loading';
import {
  loadingSpinnerStyle,
} from './config';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function AdminPage(){
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async() => {
      setLoading(true);
      var users = await axios
      .get(process.env.REACT_APP_BACKEND_URL + '/users/all')
      .then((response) => response.data.users)
      .catch((error) => {
        alert(error.response.data.message);
      });
      setUserList(users);
      setLoading(false);
  }

  const addUser = () => {
    axios.put(process.env.REACT_APP_BACKEND_URL + '/users/create', { email: newUserEmail })
    .then((response) => {
      alert("Added user: " + newUserEmail);
      setNewUserEmail(newUserEmail);
      fetchUsers();
    })
    .catch((error) => {
      alert(error.response.data.message);
    });
  };

  const deleteUser = (id, email) => {
    var check = window.confirm("Are you sure you wish to delete " + email + "?");
    if(check){
      axios.delete(process.env.REACT_APP_BACKEND_URL + '/users/delete', { data: { id: id } })
      .then((response) => {
        alert("Deleted user: " + email);
        fetchUsers();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
    }
  }

  const createUser = (user) => {
    var email = user.email
    var userLink = "/video_access?id=" + user._id;
    var updateLink = <Link to={userLink}>Update</Link>;
    var deleteIcon = <DeleteIcon onClick={()=> deleteUser(user._id, user.email)} style={{marginBottom:"-7px"}}/>;
    var actions = [updateLink, deleteIcon];
    var video_access = user.videoAccessName.join();
    return { email, video_access, actions };
  }

  const navigateToMain = () => {
    return navigate("/");
  };

  const emailTextChange = (e) => {
    setNewUserEmail(e.target.value);
  };
    return (
      <Container maxWidth={false}>
        <div
          id="loading"
          style={{ display: loading ? 'block' : 'none' }}
        >
          <ReactLoading
            type="spin"
            height={'20%'}
            width={'20%'}
            style={loadingSpinnerStyle}
          />
        </div>
        <Button variant="outlined" onClick={navigateToMain}>Back</Button>
        <Grid container spacing={2} style={{marginTop: "10px"}}>
        <Grid item xs={12}>
            <Item><h1>Data Labelling Admin Dashboard</h1></Item>
          </Grid>
          <Grid item xs={8}>
            <Item>
              <h2>List of labellers</h2>
              <LabellerTable users={userList} createUser={createUser} deleteUser={deleteUser}/>
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <h2>Add new labellers</h2>
              <div style={{display:"flex", alignItems: "baseline",justifyContent: "center", gap: "10px", paddingBottom:"20px"}}>
                <TextField type="email" id="outlined-basic" label="Labeller Email" variant="outlined" value={newUserEmail} onChange={emailTextChange}/>
                <Button variant="outlined" onClick={addUser}>Add</Button>
              </div>
            </Item>
          </Grid>
        </Grid>
      </Container >
      );
}


export default AdminPage;
